<template>
    <div class="pa-16">
        <v-card
        elevation="2"
        class="mx-auto pa-2 py-4"
        width="400px"
        shaped
        >
            <v-card-text>
            <div>Login Your Petnet Account</div>
            <p class="display-1 text--primary font-weight-bold">
                ORMP SYSTEM
            </p>
            <v-btn @click="signInGoogle">
                <v-avatar class="mr-2">
                <v-img
                    src="@/assets/google-logo.png"
                    contain
                />
                </v-avatar>
                Login Via Google
            </v-btn>
            </v-card-text>      
        </v-card>        
    </div>
</template>
<script>
import { firebase,firebaseAuth } from '../plugins/firebase';
export default {
    data: () => ({
        
    }),
    firestore(){
      return {
        UserAccounts: this.$db.collection('UserAccounts'),
      }
    },
    methods:{
      signInGoogle(){
        let self = this
        var provider = new firebase.auth.GoogleAuthProvider();
        // provider.addScope('https://www.googleapis.com/auth/contacts.readonly');
        provider.setCustomParameters({
          hd: "petnet.com.ph",
          prompt: "select_account"
        });
        firebase.auth().signInWithPopup(provider).then(function(result) { 
          // This gives you a Google Access Token. You can use it to access the Google API.
          // var token = result.credential.accessToken;
          // The signed-in user info.

          // console.table(result,'result');
          let user = {...result.user};
          let email = user.email;

          if(self.$lodash.findIndex(self.UserAccounts,a=> {return a.emailAdd == email}) == -1){
            self.$dialog.error({
              text: 'You have no access in this system. Please contact risk management team to have access.',
              title: 'User unauthorized'
            })           
            firebaseAuth.signOut()
            return
          }
          console.log(user,'user');
          let userdetails = self.$lodash.filter(self.UserAccounts,a=> {return a.emailAdd == email})[0]
          let store = {
            displayName: user.displayName,
            email: user.email,
            photoURL: user.photoURL,
            uid: user.uid,
            refreshToken: user.refreshToken,
            userDBKey: userdetails['.key'],
            department: userdetails.department,
            products: userdetails.products,
            userRole: userdetails.userRole
          }
2
          self.$store.commit('useraccount/setDashboardUser', store)
          self.$router.push('/')
          // ...
        }).catch(function(error) {
          console.log(error,'error')
          // Handle Errors here.
          // var errorCode = error.code;
          // var errorMessage = error.message;
          // // The email of the user's account used.
          // var email = error.email;
          // // The firebase.auth.AuthCredential type that was used.
          // var credential = error.credential;
          // ...
        });
      },
    }
}
</script>